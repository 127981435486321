@tailwind base;
@tailwind components;
@tailwind utilities;

// [Styling sidebar] //
// For: Firefox
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

// For: Chrome, Edge, and Safari
*::-webkit-scrollbar {
  width: 18px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
}
